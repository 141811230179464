import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import HetHuisIMG from '../assets/images/Het_huis.jpg';
import afbeelding1 from '../assets/images/Kamer.jpg';
import afbeelding2 from '../assets/images/Kamerraam.jpg';
import afbeelding3 from '../assets/images/wintersport.jpg';
import Heroimg from '../assets/images/backgroundimage.jpg';
import Footer from '../components/Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Nav from '../components/Nav';
import mobilehero from '../assets/images/notext_background.jpg';
import 'normalize.css';

const BodyStyles = styled.div`
  .grid-container-hero {
    background-image: url(${Heroimg});
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
  }

  .subtitel {
    margin-top: 50vh;
    font-size: 25px;
    color: #ffffff;
    text-align: center;
  }

  #scroll {
    position: relative;
    bottom: 25vh;
    color: #14284e;
    text-align: center;
    font-size: 16px;
  }

  .wrapper {
    .has-open-nav & {
      background: #ffffff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
  }

  .hamburger div {
    transition: 500ms ease;
    background-color: #ffffff;
    .has-open-nav & {
      background-color: #14284e;
      margin-top: -1px;
    }
  }

  .hamburger {
    transition: 500ms ease;
    .has-open-nav & {
      margin-bottom: 0;
    }
    div:nth-of-type(1) {
      .has-open-nav & {
        transform: rotate(45deg);
        position: relative;
        top: -1px;
        transition: 500ms ease;
      }
    }
    div:nth-of-type(2) {
      .has-open-nav & {
        display: none;
        transition: 500ms ease;
      }
    }
    div:nth-of-type(3) {
      .has-open-nav & {
        transform: rotate(-45deg);
        position: relative;
        top: -4px;
        transition: 500ms ease;
      }
    }
  }

  a {
    color: white;
  }

  .none {
    display: none;
  }

  .grid-container-kopje1 {
    height: 450px;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 500px);
    grid-template-rows: 1fr;
    align-items: center;
    grid-gap: 0px 50px;
  }

  .flex {
    display: flex;
    height: inherit;
    align-items: center;
  }

  #hethuisimg {
    height: 308px;
    width: 532px;
    box-shadow: none;
  }

  .wrapper_images {
    height: 300px;
    width: 500px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  }

  h2 {
    font-size: 35px;
    font-weight: 600;
    color: #14284e;
    width: 510px;
    height: 80px;
    margin-bottom: 0px;
  }

  .text {
    font-size: 16px;
    color: #14284e;
    line-height: 30px;
    width: 500px;
    height: 120px;
  }

  .wrapper {
    border: none;
    position: fixed !important;
    .Navbar-scrolldown & {
      border-bottom: 3px solid rgba(0, 0, 0, 0.2) !important;
    }
  }



  #buttonselect {
    justify-content: center;
    align-items: center;
    margin: 30px auto 60px auto;
  }


  .seizoenen {
    font-size: 25px;
    color: #14284e;
    font-weight: 700;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #14284e;
    margin-top: 50px;
  }

  h4 {
    font-size: 35px;
    color: #14284e;
    font-weight: 700;
    text-align: center;
  }

  h5 {
    font-size: 35px;
    color: #14284e;
    font-weight: 700;
    text-align: center;
    margin: 35px 0px;
  }


  h5 {
    font-size: 35px;
    color: #14284e;
    font-weight: 700;
    text-align: center;
    margin: 50px 0px 40px 0px;
  }

  #button1 {
    margin: 10px auto 50px auto;
  }

  .grid-container-indruk {
    background-color: #ffffff;
    justify-content: center;
    display: grid;
    grid-gap: 0px 100px;
    grid-template-columns: repeat(3, 255px);
    grid-template-rows: 1fr;
    justify-items: center;
  }

  .grid-container-indruk-tekst p {
    font-size: 16px;
    color: #14284e;
    text-align: center;
  }

  .indrukimg {
    width: 120%;
    height: 25vh;
  }

  #buttonchange {
    margin: 40px auto 60px auto;
    width: 220px;
    justify-content: center;
  }

  .margin-paragraph {
    margin-bottom: 30px;
  }

  .line::after {
    content: '';
    height: 60px;
    width: 3px;
    background-color: #14284e;
    animation: lineanimation 4s cubic-bezier(0.66, 0.07, 0.36, 1) infinite;
    position: absolute;
    top: 80vh;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
  }

  h5 {
  }

  @keyframes lineanimation {
    0% {
      height: 0px;
    }

    50% {
      height: 60px;
      transform: rotate(180);
    }

    100% {
      height: 0px;
    }
  }

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  /* -- This is where it get's ugly  */
  .mainMenu {
    a {
      @media (max-width: 1012px) {
        .has-open-nav & {
          color: #14284e;
        }
      }
    }
  }

  @media (min-width: 998px) {
    .link {
      color: white;
      .has-open-nav & {
        color: white;
      }
    }
  }
  /* -- ^^ */

  @media (min-width: 1200px) {
    h3 {
      margin: 40px 0px 40px 0px;
      font-size: 35px;
    }
  }

  @media (max-width: 1200px) {
    h3 {
      margin: 40px 0px 40px 0px;
      font-size: 24px;
    }

    #button1 {
      margin-top: 40px;
    }

    #buttonselect {
      margin-top: 50px;
    }

    div.grid-container-tarieven {
      height: 800px;
    }

    .grid-container-kopje1 {
      justify-items: center;
      grid-template-columns: repeat(2, 450px);
    }

    .wrapper_images {
      width: 400px;
    }

    .text {
      font-size: 16px;
      width: 400px;
    }

    h2 {
      height: 50px;
      font-size: 24px;
      width: 400px;
    }

    .grid-container-child-tarieven {
      grid-gap: 0px;
    }

    .wintertarief {
      margin-right: 0px;
    }

    .zomertarief {
      margin-left: 0px;
    }

    .grid-container-indruk {
      grid-gap: 0px 50px;
      grid-template-columns: repeat(3, 255px);
    }

    .indrukimg {
      width: 100%;
      height: 25vh;
    }
  }

  @media (max-width: 997px) {
    h3 {
      margin: 0px 0px 40px 0px;
      font-size: 24px;
    }

    #button1 {
      margin-top: 30px;
    }

    #img1 {
      margin-top: 50px;
    }

    .grid-container-hero {
      overflow: hidden;
      background-image: url(${mobilehero});
      background-size: cover;
      background-position: center;
      height: 100vh;
      width: 100%;
      margin-top: -5px;
    }

    .Logocolor {
      color: #ffffff;
    }

    .none {
      display: inline-block;
      margin-top: 35vh;
      color: #ffffff;
      font-size: 8vw;
      margin-bottom: 5px;
    }

    .tekstwrapper {
      text-align: center;
    }

    .subtitel {
      margin-top: 0;
      font-size: 18px;
    }

    .grid-container-kopje1 {
      grid-template-columns: repeat(1, 400px);
      grid-template-rows: repeat(1, 300px);
      height: 750px;
      align-content: center;
      grid-gap: 50px 0px;
    }

    div.grid-container-tarieven {
      height: 1370px;
    }

    .grid-container-child-tarieven {
      grid-template-columns: repeat(1, 350px);
      grid-gap: 50px 0px;
    }

    .grid-container-indruk {
      grid-template-columns: repeat(1, 400px);
      grid-gap: 50px 0px;
      & img {
        width: 400px;
        height: 300px;
      }
    }
  }

  @media (max-width: 500px) {
    h3 {
      font-size: 24px;
      margin: 0px 0px 40px 0px;
    }
    .grid-container-tarieven {
      height: 1150px !important;
    }
    h4,
    h5 {
      font-size: 25px;
    }
    .wintertarief p,
    .zometarief p {
      font-size: 15px;
    }
    .seizoenen {
      font-size: 18px;
    }
    #img1 {
      margin-top: -10px;
    }
    .grid-container-kopje1 {
      height: 735px;
    }

    .content_wrapper {
      margin-top: -40px;
      margin-left: 30px;
    }

    #button1 {
      margin: 0;
    }

    .wrapper_images {
      width: 300px;
      height: 250px;
      margin-top: 60px;
    }

    #hethuisimg {
      width: 350px;
      height: 250px;
    }

    h2 {
      width: 300px;
      font-size: 24px;
      margin: 0px 0px 55px 0px;
    }

    .text {
      width: 300px;
      height: 200px;
      font-size: 15px;
    }

    .orangebutton {
      margin-bottom: 60px;
    }

    .wintertarief {
      height: 375px;
      width: 220px;
      h4 {
        font-size: 25px;
      }
      p {
        font-size: 14px;
        margin-top: 14px;
      }
      .seizoenen {
        font-size: 20px;
      }
    }
    .zomertarief {
      height: 410px;
      width: 220px;
      h5 {
        font-size: 25px;
      }
      p {
        font-size: 14px;
        margin-top: 14px;
      }
      .seizoenen {
        font-size: 20px;
      }
    }
    .grid-container-indruk img {
      width: 350px;
      height: 250px;
    }
    .grid-container-tarieven {
      height: 1050px;
    }
  }
  .grid-container-beschikbaarheid {
    .beschikbaarheid {
      margin: 60px auto 0px auto;
      max-width: 380px;
      background-color: #e8f0fb;
      box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.2);
      padding: 10px 40px;
    }
    h5 {
      margin: 30px 0px;
    }
    p {
      text-align: center;   
    }
    ul {
      width: 70%;
      margin: auto;
      @media (max-width: 500px) {
        width: auto;
      }
    }
    .last-minute-price {
      line-height: 25px;
    }
    
  }
  
  .buttonmargin {
    margin: auto;
  }
`;

const Home = () => (
  <BodyStyles>
    <GlobalStyles />
    <Typography />
    <Nav />
    <div className="main frontpage">
      {/* Landingspage */}
      <div className="grid-container-hero">
        <div className="tekstwrapper">
          <h1 className="none">GROOTS GENIETEN</h1>
          <p className="subtitel">
            Appartement in Brixen Im Thale - Zomerverhuur - Winterverhuur
          </p>
        </div>
        <span id="scroll">Scroll down</span>
        <div className="line" />
      </div>
      <div className="grid-container-kopje1">
        <div className="wrapper_images" id="img1">
          <img
            draggable="false"
            id="hethuisimg"
            alt="image8"
            src={HetHuisIMG}
          />
        </div>
        <div className="content_wrapper">
          <h2>Welkom op de website van ons appartement Adelhof!</h2>
          <p className="text">
            Adelhof heeft een woonkamer, een volledig ingerichte keuken met
            ruime eethoek, drie slaapkamers en twee badkamers. De slaapkamers
            bieden ruimte voor 3 personen, wat een verblijf met 8 personen
            mogelijk maakt.
          </p>
          <Link to="/hethuis">
            <button type="button" className="orangebutton" id="button1">
              Lees meer
            </button>
          </Link>
        </div>
      </div>

      {/* Kopje 3 */}
      <div className="grid-container-beschikbaarheid">
        <div className="beschikbaarheid">
          <h5>Beschikbaarheid</h5>
          <p className="last-minute-price">
            <strong>Last minute prijs:</strong>
            {' '}
            Op dit moment zijn er geen last minutes beschikbaar.
            Neem contact op voor algemene beschikbaarheid.
          </p>
        </div>
      </div>
      {/* Kopje 4 */}
      <div className="grid-container-indruk-tekst">
        <h5>Indruk van de omgeving</h5>
      </div>
      <div className="grid-container-indruk-tekst margin-paragraph">
        <p>Bekijk de prachtige foto&apos;s van ons appartment!</p>
      </div>
      <div className="grid-container-indruk">
        <img
          draggable="false"
          className="indrukimg"
          alt="image1"
          src={afbeelding1}
        />
        <img
          draggable="false"
          className="indrukimg"
          alt="image2"
          src={afbeelding2}
        />
        <img
          draggable="false"
          className="indrukimg"
          alt="image3"
          src={afbeelding3}
        />
      </div>
      <Link to="/fotos" className="buttonmargin">
        <button type="button" id="buttonchange" className="orangebutton">
          Bekijk meer foto&apos;s
        </button>
      </Link>
    </div>
    <Footer />
  </BodyStyles>
);

export default Home;
